import { Widget } from './widget';

export class InputWidget extends Widget {
	type: string = 'input';
	inputType!: string;

	constructor(options: {
		value?: any;
		name?: string;
		label?: string;
		required?: boolean;
		inputType?: string;
	} = {}) {
		super(options);
		this.inputType = options.inputType || 'text';
	}
}
