import {Component, ComponentRef, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ConfiguratorService} from "../configurator.service";
import {Context} from "../context.service";
import {APIService} from "../api.service";
import {Node} from "../nodes/Node";
import {TraversalContext, TreeTraversal} from "../tree-traversal";
import {ModelNode} from "../nodes/ModelNode";
import {FormWizardComponent} from "../form-wizard/form-wizard.component";
import {FormSimpleComponent} from "../form-simple/form-simple.component";
import {SceneComponent} from "../scene/SceneComponent";

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
	public loaded: boolean = false;
	public finished: boolean = false;

	@ViewChild('main', { read: ViewContainerRef }) viewContainerRef!: ViewContainerRef;

	constructor(
		private configurator: ConfiguratorService,
		private context: Context,
		private api: APIService,
	) {}

	ngOnInit(): void {
		this.configurator.onLoaded().subscribe((_root: Node) => {
			this.loaded = true;
			this.rebuild();
			// this.debug().then();
		});
	}

	private getRendererComponent(name: string): ComponentRef<SceneComponent> {
		switch (name) {
			case 'wizard': return this.viewContainerRef?.createComponent(FormWizardComponent);
			case 'simple':
			default:
				return this.viewContainerRef?.createComponent(FormSimpleComponent);
		}
	}

	rebuild() {
		if (!this.loaded)
			return;

		console.log('[+] Forming ;)');
		this.finished = false;
		this.viewContainerRef.clear();

		const scene = this.configurator.getScene();
		const rootNode = scene.root();
		if (!(rootNode instanceof ModelNode)) {
			throw new Error('Root node is not ModelNode');
		}

		const component = this.getRendererComponent(rootNode.getRenderer());
		component.instance.setNode(rootNode);
	}

	update() {
		console.log('[+] main::update');
	}

	async debug() {
		console.log('+------------------------------------ DEBUG -------------------------------------');
		await TreeTraversal.depth<Node>(
			this.configurator.getScene().root(),
			{
				subnodesAccessor: (node: Node) => { return node.getSubnodes(); },
				onNode: (node: Node, next: () => void, context: TraversalContext<Node>) => {
					console.log('+--' + '--'.repeat(context.level) + 'NODE', node.toString());
					next();
				},
			});
		console.log('+--------------------------------------------------------------------------------');
	}
}
