import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ColorPickerModule } from 'ngx-color-picker';

// import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WidgetComponent } from './widget/widget.component';
import { WizardComponent } from './wizard/wizard.component';
import { ActiveStagePipe } from './active-stage.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { BomOutputComponent } from './bom-output/bom-output.component';
import { HasWidgetPipe } from './has-widget.pipe';
import { MainComponent } from './main/main.component';
import { MultiRootComponent } from './multi-root/multi-root.component';
import { FormSimpleComponent } from './form-simple/form-simple.component';
import { FormWizardComponent } from './form-wizard/form-wizard.component';
import { ActiveWizardStepPipe } from './active-wizard-step.pipe';
import { FilterInputNodePipe } from './filter-input-node.pipe';
import { TranslatePipe } from './translate.pipe';
import {MatSelectModule} from "@angular/material/select";
import {MatTableModule} from "@angular/material/table";
import {MatInputModule} from "@angular/material/input";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatRadioModule} from "@angular/material/radio";
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

@NgModule({
	declarations: [
		AppComponent,
		WidgetComponent,
		WizardComponent,
		ActiveStagePipe,
		BomOutputComponent,
		HasWidgetPipe,
		MainComponent,
		MultiRootComponent,
		FormSimpleComponent,
		FormWizardComponent,
		ActiveWizardStepPipe,
		FilterInputNodePipe,
		TranslatePipe
	],
	imports: [
		BrowserModule,
		// AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		ColorPickerModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatTableModule,
		MatInputModule,
		MatStepperModule,
		MatExpansionModule,
		MatSnackBarModule,
		MatIconModule,
		MatRadioModule,
		MatSelectModule,
		MatCheckboxModule,
		BrowserAnimationsModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
