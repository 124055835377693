import { EventEmitter, Injectable } from '@angular/core';
import { Domain } from './domain/Domain';
import { Model } from './domain/Model';
import { PresetAction } from './domain/Preset';
import { TriggerEvent } from './exec/TriggerEvent';
import {ModelInstance, ModelRef, StateResponse} from "./api/StateResponse";
import {PatchRequest} from "./api/PatchRequest";
import {APIService} from "./api.service";
import {Field} from "./domain/Field";
import {AllocRequest} from "./api/AllocRequest";
import {ModelNode} from "./nodes/ModelNode";
import {SceneGraph} from "./scene/SceneGraph";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
	providedIn: 'root'
})
export class Context {
	private events: any = {};
	private domain: Domain | null = null;
	private scene: SceneGraph | null = null;
	private state: StateResponse | null = null;
	private modelsById: Map<string, Model> = new Map();

	private onChangeEmitter: EventEmitter<any> = new EventEmitter();
	private onValidateEmitter: EventEmitter<Field> = new EventEmitter();
	private onOutputChangeEmitter: EventEmitter<any> = new EventEmitter();

	constructor(
		private api: APIService,
		private snackbar: MatSnackBar
	) {
		(<any>window).__contextHandle = this;
		this.reset();
	}

	load(state: StateResponse) {
		this.state = state;
		this.onOutputChangeEmitter.emit(this.state);
	}

	hasState(): boolean {
		return !!this.state;
	}

	getState(): StateResponse | null {
		return this.state;
	}

	getRootRef(): ModelRef {
		if (!this.state)
			throw new Error('State data is not set');
		return this.state.root;
	}

	getSessionId(): string {
		if (!this.state)
			throw new Error('State data is not set');
		return this.state.id;
	}

	getRootInstance(): Model {
		return this.getInstance(this.getRootRef());
	}

	getInstance(ref: ModelRef): Model {
		const model = this.modelsById.get(ref.__ref__);
		if (!model)
			throw new Error(`getInstance(): cannot find instance ${ref.__ref__}`);
		return model;
	}

	registerModelInstance(model: Model) {
		const modelId = model.getId();
		if (!modelId) {
			throw new Error(`Attempt to register model instance without ID: ${model.getName()}`);
		}
		this.modelsById.set(modelId, model);
	}

	async changed(patches: PatchRequest[]) {
		if (patches.length == 0)
			return;

		for (let patch of patches) {
			if (Object.keys(patch.patch).length == 0)
				continue;

			try {
				const patchResponse = await this.api.patchModel(this.getSessionId(), patch);
				this.state = patchResponse.state;

				for (let delta of patchResponse.deltas) {
					this.setWidgetValue(delta.model_id, delta.field_name, delta.value);
					this.setWidgetValidity(delta.model_id);
				}
			}
			catch (err) {
				console.warn(`Failed to patch: ${patch.id} (${patch.model})`);
			}
		}

		this.onChange().emit();
		this.onOutputChangeEmitter.emit(this.state);
	}

	async allocate(requests: AllocRequest[]): Promise<Model[]> {
		if (requests.length == 0 || !this.domain)
			return [];

		const models = [];
		for (let req of requests) {
			try {
				const response = await this.api.allocModel(this.getSessionId(), req);
				this.state = response.state;

				// Create a new model instance
				const instance = this.domain.deserialize(this.state.by_id[response.reference.__ref__]);
				this.registerModelInstance(instance);
				models.push(instance);

				for (let delta of response.deltas) {
					this.setWidgetValue(delta.model_id, delta.field_name, delta.value);
					this.setWidgetValidity(delta.model_id);
				}
			}
			catch (err) {
				console.warn(`Failed to allocate: ${req.model} (${req.parent}.${req.field})`);
			}
		}
		return models;
	}

	setWidgetValue(modelId: string, fieldName: string, value: any) {
		const model = this.modelsById.get(modelId);
		if (!model)
			return;
		const field = model.getField(fieldName);
		if (!field)
			return;
		let widget = field.getWidget();
		widget.block();
		widget.setValue(value);
		widget.unblock();
	}

	setWidgetValidity(modelId: string) {
		if (!this.state || !this.state.by_id[modelId]) {
			return;
		}

		const model = this.modelsById.get(modelId);
		if (!model)
			return;

		const validity = this.state.by_id[modelId].validity;
		for (let field_name in validity) {
			const field = model.getField(field_name);
			if (!field)
				continue;
			const widget = field.getWidget();
			widget.setValidityState(validity[field_name]);
		}
	}

	broadcast(actions: PresetAction[]): void;
	broadcast(model: string, field: string, value: any): void;
	broadcast(modelOrActions: any, field?: string, value?: any): void {
		if (typeof(modelOrActions) === 'string' && field) {
			for (let model of this.getDomain().allocated()) {
				if (model.getName() !== modelOrActions)
					continue;
				model.getField(field)!.setValue(value);
			}
			return;
		}

		if (Array.isArray(modelOrActions)) {
			for (let action of modelOrActions) {
				this.broadcast(action.model, action.field, action.value);
			}
			return;
		}

		throw new Error(`Bad broadcast: (${modelOrActions}, ${field}, ${value})`);
	}

	reset() {
		this.modelsById.clear();

		this.events[TriggerEvent.ON_CHANGE] = {};
		this.events[TriggerEvent.ON_CREATE] = {};
		this.events[TriggerEvent.ON_FINISH] = {};
	}

	getDomain(): Domain {
		if (!this.domain)
			throw new Error('Context has no domain reference');

		return this.domain;
	}

	setDomain(domain: Domain) {
		this.domain = domain;
	}

	getSceneGraph(): SceneGraph {
		if (!this.scene)
			throw new Error('Context has no scene graph reference');
		return this.scene;
	}

	setSceneGraph(scene: SceneGraph) {
		this.scene = scene;
	}

	register(type: TriggerEvent, modelName: string, triggerField: string, computeField: string) {
		if (!this.events[type][modelName])
			this.events[type][modelName] = {};

		if (!this.events[type][modelName][triggerField])
			this.events[type][modelName][triggerField] = [];

		this.events[type][modelName][triggerField].push(computeField);
	}

	notify(message: string) {
		this.snackbar.open(message, 'Close', {
			duration: 3000
		});
	}

	onChange(): EventEmitter<any> {
		return this.onChangeEmitter;
	}

	onValidate(): EventEmitter<any> {
		return this.onValidateEmitter;
	}

	onOutputChange(): EventEmitter<any> {
		return this.onOutputChangeEmitter;
	}
}
