export type WizardData = { [key: string]: any; };

export enum SceneRenderer {
	WIZARD = 'wizard',
	GRID = 'grid',
}

export interface GridData {
	// TODO
}

export interface SceneGraphResponse {
	renderer: SceneRenderer;
	scene: WizardData | GridData;
}
