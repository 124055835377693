import { InputWidget } from './widget.input';

export class TextWidget extends InputWidget {
	type: string = 'text';
	inputType!: string;

	constructor(options: {
		value?: string | null;
		name?: string;
		label?: string;
		required?: boolean;
	} = {}) {
		super({...options, ...{inputType: 'text'}});
	}

	getValue(): string | null {
		if (typeof(this.value) === 'string' && this.value.length === 0)
			return null;
		return this.value;
	}
}
