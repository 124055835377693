import { Widget } from './widget';
import {Field} from "../domain/Field";
import {ModelNode} from "../nodes/ModelNode";
import {SceneComponent} from "../scene/SceneComponent";
import {ListType} from "../types/ListType";

export class ListWidget extends Widget {
	type: string = 'list';
	field: Field;
	types: Set<string> = new Set();

	protected current: string | null = null;
	protected components: Map<string, SceneComponent> = new Map();

	constructor(options: {
		value?: string | null;
		name?: string;
		label?: string;
		required?: boolean;
		field?: Field;
		types?: Set<string>;
	} = {}) {
		super(options);
		this.field = options.field!;
		this.types = options.types || new Set();
	}

	async new() {
		if (!this.node)
			return;

		const modelId = this.node.getModel().getId();
		if (!modelId)
			return;

		if (this.types.size == 0)
			return;

		const ctx = this.node.getContext();
		if (!ctx)
			return;

		// TODO: this is currently limited to just one type
		const {value, done} = this.types.values().next();

		const instances = await ctx.allocate([{
			parent: modelId,
			field: this.name,
			model: value,
		}]);

		if (!instances)
			return;

		// Update SceneGraph and build new form logic for the new ModelNode
		for (let model of instances) {
			const node: ModelNode = new ModelNode(model, this.node, 'simple'); // TODO: renderer must come form data
			this.node.add(node);
			ctx.getSceneGraph().addModelNode(node);
		}
	}

	async delete(ref: string) {
		if (!confirm(`Are you sure?`))
			return;

		const index = this.value.indexOf(ref);
		if (index === -1) {
			return;
		}
		this.value.splice(index, 1);
		this.current = null;
		this.onChange(this.value);
	}

	opened(ref: string) {
		this.current = ref;
	}

	async closed(ref: string) {
		this.current = null;

		if (!this.node)
			return;

		const ctx = this.node.getContext();
		if (!ctx)
			return;

		const node = this.getModelNodeByRef(ref);
		if (!node)
			return;

		const component = this.components.get(ref);
		if (!component)
			return;

		await ctx.changed(component.serialize());
	}

	canAdd(): boolean {
		const type = this.field.getType() as ListType;
		if (!type.canAdd())
			return false;

		return !(type.getMax() > 0 && this.value.length >= type.getMax());
	}

	canDelete(): boolean {
		return (this.field.getType() as ListType).canDelete();
	}

	getModelNodeByRef(ref: string): ModelNode | null {
		if (!this.node)
			return null;

		for (let subnode of this.node.getSubnodes()) {
			if (subnode instanceof ModelNode && subnode.getModel().getId() === ref)
				return subnode;
		}
		return null;
	}

	getCurrentComponent(): SceneComponent | undefined {
		if (!this.current)
			return undefined;
		return this.components.get(this.current);
	}

	onFormCreated(ref: string, component: SceneComponent) {
		this.components.set(ref, component);
	}
}
