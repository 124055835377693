import * as yaml from 'js-yaml';
import { TypeResolver } from '../type-resolver.class';
import { TypeInfo } from '../types/TypeInfo';

function dummyRepresent(data: any) {
	console.log('dummyRepresent:', data);
	return 'TODO';
}

export const OptionalType = new yaml.Type('tag:yaml.org,2002:optional', {
	kind: 'scalar',
	resolve: (_data: any) => { return true; },
	construct(data: any): TypeInfo {
		// console.log('construct(optional):', data);
		const type = TypeResolver.resolve(data);
		type.setRequired(false);
		return type;
	},
	predicate: function(_object: any) { return true; },
	represent: dummyRepresent
});
