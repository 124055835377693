import { Widget } from './widget';
import {ValidityState} from "../api/StateResponse";
import {Observable, startWith, map} from "rxjs";

export interface SelectWidgetOptionType {
	key: string;
	value: string;
	enabled: boolean;
}

export class SelectWidget extends Widget {
	type: string = 'select';
	multiple: boolean = false;
	options!: SelectWidgetOptionType[];
	selected: string[];

	constructor(options: {
		value?: string | null;
		name?: string;
		label?: string;
		required?: boolean;
		multiple?: boolean;
		options?: SelectWidgetOptionType[];
	} = {}) {
		super(options);

		this.selected = [];
		this.options = options.options || [];
		this.multiple = options.multiple || false;
		if (this.required && this.options.length > 0) {
			this.selected = [this.options[0].value];
		}
	}

	onChange(event: any) {
		if (this.blocked) {
			return;
		}

		if (this.node)
			this.node.onWidgetChange(event);
	}

	getValue(): any {
		if (this.multiple) {
			return this.selected;
		}

		if (!Array.isArray(this.selected)) {
			return this.selected;
		}

		if (this.selected.length === 0) {
			return null;
		}

		return this.selected[0];
	}

	setValue(value: any) {
		if (Array.isArray(value)) {
			this.selected = value;
		}
		else if (value !== null) {
			this.selected = value;
		}
		else {
			this.selected = [];
		}

		if (this.blocked) {
			return;
		}
		this.onChange(value);
	}

	setValidityState(validity: ValidityState) {
		super.setValidityState(validity);

		for (let opt of this.options) {
			if (!(opt.key in validity.map)) {
				opt.enabled = true;
				continue;
			}
			opt.enabled = !!validity.map[opt.key];
		}
	}
}

export class RadioWidget extends SelectWidget {
	type: string = 'radio';

	constructor(options: {
		value?: string | null;
		name?: string;
		label?: string;
		required?: boolean;
		options?: SelectWidgetOptionType[];
	} = {}) {
		super({...options, ...{
			multiple: false,
		}});
	}

	getValue(): any {
		if (!this.value) {
			return null;
		}

		return this.value;
	}

	setValue(value: any) {
		this.value = value;
		this.onChange(value);
	}
}

export class SearchWidget extends SelectWidget {
	type: string = 'search';
	filteredOptions: Observable<SelectWidgetOptionType[]>;

	constructor(options: {
		value?: string | null;
		name?: string;
		label?: string;
		required?: boolean;
		options?: SelectWidgetOptionType[];
	} = {}) {
		super(options);
		this.filteredOptions = this.formControl.valueChanges.pipe(
			startWith(''),
			map(value => this._filter(value || '')),
		);
	}

	onChange(event: any) {
		if (this.blocked) {
			return;
		}

		if (this.node)
			this.node.onWidgetChange(event);
	}

	getValue(): any {
		if (!this.value) {
			return null;
		}

		return this.value;
	}

	setValue(value: any) {
		this.value = value;
		this.onChange(value);
	}

	setValidityState(validity: ValidityState) {
		super.setValidityState(validity);

		// This is to make the autocomplete behave as the SelectWidget
		if (this.value in validity.map && !validity.map[this.value]) {
			this.setValue(null);
		}
	}

	private _filter(value: string): SelectWidgetOptionType[] {
		const filterValue = this._normalizeValue(value);
		return this.options.filter(opt => this._normalizeValue(opt.value).includes(filterValue));
	}

	private _normalizeValue(value: string): string {
		return value.toLowerCase().replace(/\s/g, '');
	}
}
