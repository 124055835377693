import { Field } from '../domain/Field';
import { Widget } from '../widget/widget';
import { WidgetFactory } from '../widget/WidgetFactory';
import { TypeInfo } from './TypeInfo';

export class Text extends TypeInfo {
	constructor(private multiline: boolean = false, private readonly: boolean = false) {
		super();
	}

	canAcceptValue(value: any): boolean {
		return typeof(value) == 'string';
	}

	getDefaultValue(): any {
		return '';
	}

	toString(): string {
		return 'string';
	}

	isReadOnly(): boolean {
		return this.readonly;
	}

	isFreeInput(): boolean {
		return true;
	}

	createWidget(field: Field): Widget {
		if (this.readonly)
			return WidgetFactory.createReadOnly(field);
		if (this.multiline)
			return WidgetFactory.createTextMultiline(field);
		return WidgetFactory.createText(field);
	}
}
