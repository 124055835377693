import { Context } from '../context.service';
import { Model } from '../domain/Model';
import { Executor } from '../exec/Executor';
import { TriggerEvent } from '../exec/TriggerEvent';
import { Widget } from '../widget/widget';

export enum NodeType {
	MODEL = 'MODEL',
	INPUT = 'INPUT',
	EXEC = 'EXEC',
	META = 'META',
	GROUP = 'GROUP',
}

export interface SerializedType {
	key: string;
	value: any;
}

export class Node {
	protected context: Context | null = null;
	protected subnodes: Node[] = [];
	protected widget!: Widget;
	protected meta: Map<string, any> = new Map();
	protected handlers: Map<TriggerEvent, Array<Executor>> = new Map();

	constructor(
		protected type: NodeType,
		protected parent: Node | null = null,
		protected model: Model
	) {
		this.handlers.set(TriggerEvent.ON_CHANGE, []);

		// Only support ON_CHANGE for now
		// this.handlers.set(TriggerEvent.ON_CREATE, []);
		// this.handlers.set(TriggerEvent.ON_FINISH, []);
	}

	isEnabled(): boolean {
		if (this.meta.has('enabled'))
			return this.meta.get('enabled');
		return true;
	}

	isLeaf(): boolean {
		return this.subnodes.length === 0;
	}

	enterSubtree(): boolean {
		return !this.isLeaf();
	}

	getType(): NodeType {
		return this.type;
	}

	getModel(): Model {
		return this.model;
	}

	getParent(): Node | null {
		return this.parent;
	}

	getSubnodes(): Node[] {
		return this.subnodes;
	}

	add(node: Node): Node {
		this.subnodes.push(node);
		return node;
	}

	getSelectedSubnodes(): Node[] {
		return this.subnodes;
	}

	findSubnode(metaKey: string, value: any): Node | null {
		for (let node of this.getSubnodes()) {
			if (node.getMetadata(metaKey) === value) {
				return node;
			}
		}
		return null;
	}

	findNeighbour(metaKey: string, value: any): Node | null {
		if (!this.parent)
			return null;
		return this.parent.findSubnode(metaKey, value);
	}

	getWidget(): Widget {
		return this.widget;
	}

	setMetadata(key: string, value: any) {
		if (this.meta.has(key)) {
			console.warn(`${this.type}: metadata key '${key}' was overridden`);
		}
		this.meta.set(key, value);
	}

	getMetadata(key: string) {
		return this.meta.get(key);
	}

	setEnabled(enabled: boolean) {
		this.meta.set('enabled', enabled);
	}

	setContext(context: Context) {
		this.context = context;
	}

	getContext(): Context | null {
		return this.context;
	}

	toString(): string {
		return 'Node';
	}

	serialize(): SerializedType {
		return {
			key: this.toString(),
			value: null,
		};
	}

	protected loadEventHandlers(data: any) {
		for (let event of Object.values(TriggerEvent)) {
			if (!data[event])
				continue;

			if (typeof(data[event]) !== 'string') {
				console.warn(`Node: ${event}: expected string but got:`, data[event]);
				continue;
			}

			if (!this.handlers.has(event)) {
				console.warn(`Node: ${event} is not supported`);
				continue;
			}

			const exe = new Executor();
			exe.setSource(data[event]);
			this.handlers.get(event)!.push(exe);
		}
	}

	protected loadMetadata(data: any) {
		for (let key in data) {
			this.setMetadata(key, data[key]);
		}
	}
}
