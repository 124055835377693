<div [ngSwitch]="widget.type">
	<mat-form-field
		appearance="outline"
		*ngSwitchCase="'select'"
		[style]="getStyleString()">
		<mat-label>
			{{ widget.label }}<sup class="required" *ngIf="widget.required">*</sup>
		</mat-label>
		<mat-select
			[(ngModel)]="getSelectWidget().selected"
			(ngModelChange)="widget.onChange($event)"
			[multiple]="getSelectWidget().multiple"
			[disabled]="!widget.enabled">
			<mat-option *ngIf="!getSelectWidget().multiple" [value]="null">
				{{ widget.noValueLabel }}
			</mat-option>
			<ng-container *ngFor="let opt of getSelectWidget().options">
				<mat-option *ngIf="opt.enabled" [value]="opt.value" [disabled]="!opt.enabled">
					{{ opt.key }}
				</mat-option>
			</ng-container>
		</mat-select>
	</mat-form-field>

	<div *ngSwitchCase="'radio'" class="widget widget-radio" [style]="getStyleString()">
		<label>
			{{ widget.label }}<sup class="required" *ngIf="widget.required">*</sup>
		</label>
		<mat-radio-group
			[disabled]="!widget.enabled"
			[(ngModel)]="getSelectWidget().value"
			(ngModelChange)="widget.onChange($event)">
			<mat-radio-button
				*ngIf="!widget.required"
				[name]="widget.id"
				[value]="null">
				{{ widget.noValueLabel }}
			</mat-radio-button>
			<mat-radio-button
				*ngFor="let opt of getSelectWidget().options"
				[name]="widget.id"
				[value]="opt.value">
				{{ opt.key }}
			</mat-radio-button>
		</mat-radio-group>
	</div>

	<mat-form-field appearance="outline" *ngSwitchCase="'search'" [style]="getStyleString()">
		<mat-label>
			{{ widget.label }}<sup class="required" *ngIf="widget.required">*</sup>
		</mat-label>

		<input
			type="text"
			[placeholder]="widget.label"
			[disabled]="!widget.enabled"
			[(ngModel)]="widget.value"
			[formControl]="widget.formControl"
			[matAutocomplete]="auto"
			matInput>
		<button *ngIf="widget.value" matSuffix mat-icon-button aria-label="Clear" (click)="widget.setValue(null)">
			<mat-icon>close</mat-icon>
		</button>
		<mat-autocomplete #auto="matAutocomplete" (optionSelected)="widget.onChange($event)">
			<ng-container *ngFor="let opt of getSearchWidget().filteredOptions | async">
				<mat-option *ngIf="opt.enabled" [value]="opt.value" [disabled]="!opt.enabled">
					{{ opt.key }}
				</mat-option>
			</ng-container>
		</mat-autocomplete>
	</mat-form-field>

	<div *ngSwitchCase="'list'" class="widget widget-list" [style]="getStyleString()">
		<label>
			{{ widget.label }}<sup class="required" *ngIf="widget.required">*</sup>
		</label>
		<mat-accordion>
			<mat-expansion-panel
				*ngFor="let ref of widget.value; let i = index"
				(opened)="getListWidget().opened(ref)"
				(closed)="getListWidget().closed(ref)">
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{ i + 1 }}
					</mat-panel-title>
					<mat-panel-description>
						{{ getListWidgetDescription(ref) }}
					</mat-panel-description>
				</mat-expansion-panel-header>
				<div>
					<!-- TODO: support other types of form components here -->
					<app-form-simple [node]="getListWidget().getModelNodeByRef(ref)" (created)="getListWidget().onFormCreated(ref, $event)"></app-form-simple>
					<button
						*ngIf="getListWidget().canDelete()"
						mat-icon-button color="warn"
						class="float-right"
						(click)="getListWidget().delete(ref)">
						<mat-icon>delete_outline</mat-icon>
					</button>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
		<button
			*ngIf="getListWidget().canAdd()"
			mat-icon-button color="primary"
			(click)="getListWidget().new()">
			<mat-icon>playlist_add</mat-icon>
		</button>
		<br />
		<br />
	</div>

	<div *ngSwitchCase="'color'" class="widget widget-color" [style]="getStyleString()">
		<input
			[disabled]="!widget.enabled"
			[(colorPicker)]="widget.value"
			[value]="widget.value"
			(colorPickerChange)="widget.onChange($event)" />
		<span [(colorPicker)]="widget.value" [style.background]="widget.value"></span>
		<br style="clear: both;" />
	</div>

	<div *ngSwitchCase="'bool'" class="widget widget-bool" [style]="getStyleString()">
		<mat-checkbox
			[disabled]="!widget.enabled"
			[(ngModel)]="widget.value"
			(ngModelChange)="widget.onChange($event)">
			{{ widget.label }}
		</mat-checkbox>
	</div>

	<mat-form-field appearance="outline" *ngSwitchCase="'number'" [style]="getStyleString()">
		<mat-label>
			{{ widget.label }}<sup class="required" *ngIf="widget.required">*</sup>
		</mat-label>
		<input
			matInput
			[disabled]="!widget.enabled"
			[(ngModel)]="widget.value"
			[type]="getInputWidget().inputType"
			[min]="getNumberWidget().minValue"
			[max]="getNumberWidget().maxValue"
			[step]="getNumberWidget().step"
			[placeholder]="widget.label"
			(blur)="widget.onChange($event)"
			(ngModelChange)="widget.onChangeDebounced($event)" />
	</mat-form-field>

	<div *ngSwitchCase="'date'" class="widget widget-date">
		<input
			[(ngModel)]="widget.value"
			[disabled]="!widget.enabled"
			[type]="getInputWidget().inputType"
			[placeholder]="widget.label"
			(ngModelChange)="widget.onChange($event)" />
	</div>

	<div *ngSwitchCase="'text'" class="widget widget-text" [style]="getStyleString()">
		<textarea
			[(ngModel)]="widget.value"
			[disabled]="!widget.enabled"
			[name]="widget.id"
			rows="8"
			cols="60">
		</textarea>
	</div>

	<mat-form-field appearance="outline" *ngSwitchCase="'input'" [style]="getStyleString()">
		<mat-label>
			{{ widget.label }}<sup class="required" *ngIf="widget.required">*</sup>
		</mat-label>
		<input
			matInput
			[placeholder]="widget.label"
			[disabled]="!widget.enabled"
			[(ngModel)]="widget.value"
			[type]="getInputWidget().inputType"
			(ngModelChange)="widget.onChangeDebounced($event)" />
	</mat-form-field>

	<mat-form-field appearance="outline" *ngSwitchCase="'readonly'" [style]="getStyleString()">
		<mat-label>
			{{ widget.label }}
		</mat-label>
		<input
			matInput
			[placeholder]="widget.label"
			[disabled]="true"
			[(ngModel)]="widget.value"
			[type]="getInputWidget().inputType" />
	</mat-form-field>
</div>
