import * as yaml from 'js-yaml';
import { ExpressionExecutor } from '../exec/Executor';

function dummyRepresent(data: any) {
	console.log('dummyRepresent:', data);
	return 'TODO';
}

export const EvalType = new yaml.Type('tag:yaml.org,2002:eval', {
	kind: 'scalar',
	resolve: (_data: any) => { return true; },
	construct(data: any): any {
		const exe = new ExpressionExecutor();
		exe.setSource('`' + data + '`');
		return exe;
	},
	predicate: function(_object: any) { return true; },
	represent: dummyRepresent
});

export const ScalarType = new yaml.Type('tag:yaml.org,2002:scalar', {
	kind: 'scalar',
	resolve: (_data: any) => { return true; },
	construct(data: any): any {
		const exe = new ExpressionExecutor();
		exe.setSource(data);
		return exe;
	},
	predicate: function(_object: any) { return true; },
	represent: dummyRepresent
});
