import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as yaml from 'js-yaml';
import { OptionalType } from './yaml/Atomic';
import { EvalType, ScalarType } from './yaml/Eval';
import {firstValueFrom} from "rxjs";
// import { MultiOptionalType, MultiType, OneOptionalType, OneType, RadioType } from './yaml/Sequence';


export interface YamlData {
	source: string;
	data: any;
}

export interface JsonData {
	source: string;
	data: any;
}

export enum DataSourceType {
	GENERIC = 'generic',
	DOMAIN = 'domain',
	LOGIC = 'logic',
	BOM = 'bom',
}

@Injectable({
	providedIn: 'root'
})
export class DataLoaderService {

	constructor(private http: HttpClient) {}

	async load(url: string, type: DataSourceType = DataSourceType.GENERIC): Promise<YamlData> {
		const response = await firstValueFrom(this.http.get(url, {
			observe: 'body',
			responseType: 'text'
		}));

		return {
			source: response,
			data: this.parseYAML(response, type)
		};
	}

	parseYAML(yamlString: string, type: DataSourceType): any {
		const schema = this.getYamlSchema(type);
		return yaml.load(yamlString, { schema });
	}

	async json(url: string): Promise<JsonData> {
		const response = await firstValueFrom(this.http.get(url, {
			observe: 'body',
			responseType: 'text'
		}));

		return {
			source: response,
			data: JSON.parse(response)
		};
	}

	getYamlSchema(type: DataSourceType) {
		switch (type) {
			case DataSourceType.DOMAIN:
				return yaml.DEFAULT_SCHEMA.extend([
					// Atomic types
					OptionalType,
					// Select schemas
					// OneType, RadioType, MultiType, OneOptionalType, MultiOptionalType
				]);
			case DataSourceType.BOM:
				return yaml.DEFAULT_SCHEMA.extend([
					EvalType, ScalarType
				]);
			case DataSourceType.LOGIC:
			case DataSourceType.GENERIC:
			default:
				 return yaml.DEFAULT_SCHEMA;
		}
	}
}
