import { Model } from '../domain/Model';
import { Node, NodeType, SerializedType } from './Node';

export class ModelNode extends Node {
	constructor(
		protected model: Model,
		protected parent: Node | null = null,
		protected renderer: string = 'wizard'
	) {
		super(NodeType.MODEL, parent, model);
	}

	toString(): string {
		return `ModelNode(model=${this.model.getName()}, id=${this.model.getId()}, renderer=${this.renderer})`;
	}

	serialize(): SerializedType {
		const data: any = {};
		for (let [key, field] of this.model.getFields().entries()) {
			data[key] = field.getType().getInitialValue();
		}
		return {
			key: this.model.getName(),
			value: data
		};
	}

	getRenderer(): string {
		return this.renderer;
	}
}
