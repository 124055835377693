<ul class="multi-root-panel">
	<label id="label-select-root-model">{{ 'SELECT_ROOT' | translate }}</label>
	<mat-radio-group
		aria-labelledby="label-select-root-model"
		class="radio-group"
		color="warn"
		(change)="onChange()"
		[(ngModel)]="selected">
		<mat-radio-button class="radio-button" *ngFor="let model of candidates" [value]="model">
			<div class="model-title">{{ model.getTitle() }}</div>
			<img *ngIf="model.thumbnail" [src]="model.thumbnail" />
		</mat-radio-button>
	</mat-radio-group>
</ul>
